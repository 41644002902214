<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="close"
  >
    <el-form ref="form" label-width="150px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="币种" prop="name">
        <el-input
          v-model="form.name"
          size="small"
          placeholder="请输入币种名称"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="符号" prop="symbol">
        <el-input
          v-model="form.symbol"
          size="small"
          placeholder="请输入币种符号"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="代码" prop="code">
        <el-input
          v-model="form.code"
          size="small"
          placeholder="请输入币种代码"
        />
      </el-form-item>
      <el-form-item
        style="margin-bottom: 20px"
        label="与缺省货币兑换比例"
        prop="exchange_rate"
      >
        <el-input-number
          v-model="form.exchange_rate"
          controls-position="right"
          :min="1"
          :max="10"
          placeholder="请输入币种兑换比例"
        />
      </el-form-item>
      <el-form-item
        style="margin-bottom: 20px"
        label="追加金额"
        prop="superaddition"
      >
        <el-input-number
          v-model="form.superaddition"
          controls-position="right"
          :min="0"
          :max="10"
          placeholder="请输入追加金额"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="文本" prop="front_name">
        <el-input
          v-model="form.front_name"
          size="small"
          placeholder="请输入币种文本"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { apiAddCurrencyType } from "@/request/api";

export default {
  name: "AdminEdit",
  data() {
    return {
      query_time: [],
      apiRegion: [],
      form: {
        name: "",
        symbol: "",
        code: "",
        exchange_rate: 1,
        superaddition: 0,
        state: 0,
        front_name: "",
      },
      rules: {
        name: [
          { required: true, trigger: "blur", message: "币种名称不能为空" },
        ],
        symbol: [
          { required: true, trigger: "blur", message: "币种符号不能为空" },
        ],
        code: [
          { required: true, trigger: "blur", message: "币种代码不能为空" },
          { min: 3, trigger: "blur", message: "币种代码必须大于三位" },
        ],
        exchange_rate: [
          { required: true, trigger: "blur", message: "兑换比例不能为空" },
        ],
        superaddition: [
          { required: true, trigger: "blur", message: "追加金额不能为空" },
        ],
        front_name: [
          { required: true, trigger: "blur", message: "币种文本不能为空" },
        ],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = "添加币种";
      } else {
        this.title = "修改币种";
        const tempObj = Object.assign({}, row);
        this.form = {
          id: tempObj.id,
          name: tempObj.name,
          symbol: tempObj.symbol,
          code: tempObj.code,
          exchange_rate: tempObj.exchange_rate,
          superaddition: tempObj.superaddition,
          state: tempObj.state,
          front_name: tempObj.front_name,
        };
      }
      this.dialogFormVisible = true;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          apiAddCurrencyType(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.$emit("fetch-data");
              this.close();
            } else {
              this.$message.error(res.message);
              this.close();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
