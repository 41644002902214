<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          设置
        </template>
        <el-form :inline="true" :model="form" @submit.native.prevent>
          <el-row :gutter="20">
            <el-divider content-position="left">缺省设置</el-divider>
<!--            <el-col :span="6">-->
<!--              <el-form-item label="货币展现形式:">-->
<!--                <el-select v-model="form.default.value_conf.show_type" size="small">-->
<!--                  <el-option label="￥100" value="0"></el-option>-->
<!--                  <el-option label="￥ 100" value="1"></el-option>-->
<!--                  <el-option label="100￥" value="2"></el-option>-->
<!--                  <el-option label="100 ￥" value="3"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="6">
              <el-form-item label="缺省货币:">
                <el-select v-model="form.default.value" size="small">
                  <el-option v-for="(item,index) in list" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
<!--            <el-col :span="6">-->
<!--              <el-form-item label="数字分割:">-->
<!--                <el-select v-model="form.default.value_conf.segmentation" size="small">-->
<!--                  <el-option label="1,234.00" value="0"></el-option>-->
<!--                  <el-option label="1.234.00" value="1"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="6">-->
<!--              <el-form-item label="数字精度:">-->
<!--                <el-select v-model="form.default.value_conf.precision" size="small">-->
<!--                  <el-option label="1.2   （精确到一位）" value="0"></el-option>-->
<!--                  <el-option label="1.23  （精确到两位）" value="1"></el-option>-->
<!--                  <el-option label="1.234 （精确到三位）" value="2"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row :gutter="20">
            <el-divider content-position="left">定时更新</el-divider>
            <el-col :span="6">
              <el-form-item label="更新Token:">
                <el-tooltip class="item" effect="dark" :content="form.exchangeRate.value" placement="top">
                  <el-input v-model="form.exchangeRate.value" size="small" placeholder="请输入正确的token"/>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="每天自动进行">
                <el-input-number
                    v-model="form.exchangeRate.value_conf.number"
                    step-strictly
                    controls-position="right"
                    :min="0"
                />
                <span style="margin-left: 10px">次更新</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="当前余量:">
                {{ form.exchangeRate.value_conf.allowance }}
                <span>次 <el-link type="primary" href="https://www.baidu.com/" target="_blank">点击充值</el-link></span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下次更新时间:">
                {{ form.exchangeRate.value_conf.time | formatDate }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button @click="submitForm" type="primary" size="small">
              保存
            </el-button>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <vab-query-form-left-panel :span="24">
      <el-button class="button" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">新增</el-button>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="币种">
      </el-table-column>
      <el-table-column
          align="center"
          prop="front_name"
          label="终端显示名">
      </el-table-column>
      <el-table-column
          align="center"
          prop="symbol"
          label="符号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="code"
          label="代码"
      />
      <el-table-column
          align="center"
          prop="exchange_rate"
          label="与缺省货币兑换比例"
      />
      <el-table-column
          align="center"
          prop="superaddition"
          label="追加金额"
      />
      <el-table-column
          align="center"
          label="是否启用"
          prop="state"
          show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tooltip
              :content="row.state == 1 ? '点击禁用' : '点击启用'"
              :enterable="false"
              placement="top"
          >
            <el-switch
                v-model="row.state"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :value="true"
                @change="changeStatus(row, row.state)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Edit ref="Edit" @fetch-data="fetchData"/>
  </el-card>
</template>
<script>
import Edit from './components/Edit'
import {
  apiAddCurrencyType,
  apiGetCurrencyTypeList,
  apiDelCurrencyType,
  apiGetCurrencyConfig,
  apiUpdateCurrencyConfig
} from "@/request/api";
import {formatDate} from "@/utils/theTimeStamp";

export default {
  name: 'multiCurrency',
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd')
    },
  },
  components: {Edit},
  data() {
    return {
      form: {
        default: {
          value: '',
          value_conf: {
            show_type: '',
            segmentation: '',
            precision: '',
          }
        },
        exchangeRate: {
          value: '',
          value_conf: {
            number: '',
            allowance: '',
            time: '',
          }
        },
      },
      activeName: '1',
      listLoading: false,
      queryForm: {
        page: 1,
        pageSize: 10,
        title: '',
      },
      list: [],
    }
  },
  created() {
    this.fetchData()
    this.getCurrencyConfig()
  },
  mounted() {
  },
  methods: {
    //获取多币种配置
    getCurrencyConfig() {
      apiGetCurrencyConfig().then(res => {
        if (res.code == 200) {
          if (res.data.default.value_conf) {
            this.form.default = res.data.default
            this.form.default.value = parseInt(res.data.default.value)
          } else {
            this.form.default = {
              value: res.data.default.value,
              value_conf: {
                show_type: '',
                segmentation: '',
                precision: '',
              }
            }
          }
          if (res.data.exchangeRate.value_conf) {
            this.form.exchangeRate = res.data.exchangeRate
          } else {
            this.form.exchangeRate = {
              value: res.data.exchangeRate.value,
              value_conf: {
                number: 0,
                allowance: 0,
                time: '',
              }
            }
          }
        }
      })
    },
    //提交配置
    submitForm() {
      apiUpdateCurrencyConfig({data: this.form}).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.fetchData()
          this.getCurrencyConfig()
        } else {
          this.$message.error(res.message)
          this.getCurrencyConfig()
        }
      })
    },
    // 是否启用
    changeStatus(row, lock) {
      console.log(row.id, lock)
      if (row.id) {
        apiAddCurrencyType({
          id: row.id,
          name: row.name,
          symbol: row.symbol,
          code: row.code,
          exchange_rate: row.exchange_rate,
          superaddition: row.superaddition,
          state: lock,
        }).then(res => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.fetchData()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要操作当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDelCurrencyType({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    async fetchData() {
      this.listLoading = true
      apiGetCurrencyTypeList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style>
.button {
  margin-top: 20px;
}
</style>
